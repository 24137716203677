<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.footer {
  position: relative;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
  color: #828282;
  /* text-align: center; */
  height: 70px;
  margin-bottom: 0;
}

.wrapp {
  min-height: calc(100vh - 100px) !important;
  /* flex: 1 0 auto; */
}

#nav-style {
  background-color: rgb(30, 69, 146) !important;
}

.directLink {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  color: black;
}

.clicked {
  border-bottom: 2px solid #000;
  padding-bottom: 0.2px;
}

.navbar-brand:hover .clicked:hover {
  color: #226cfa !important;
  border-bottom: 2px solid #226cfa;
}

.rowCstm {
  border-top: 1px solid #e4e0e0 !important;
  padding-top: 20px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.responsiveHead {
  padding: 32px 80px 32px 80px !important;
}

.responsiveContainer {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.newlogo {
  width: 300px;
}

@media only screen and (max-width: 720px) {
  .responsiveHead {
    padding: 32px 42px 32px 42px !important;
  }

  .footer {
    padding-left: 42px;
    padding-right: 42px;
  }

  .newlogo {
    width: 200px;
  }

  .consulting {
    display: none;
  }
}
</style>

<template>
  <div id="app">
    <nav class="navbar responsiveHead d-flex justify-content-center">
      <router-link to="/" style="cursor: pointer"><img src="../src/assets/shazalogonew.png"
          class="newlogo" /></router-link>
    </nav>

    <div class="container-fluid wrapp responsiveContainer">
      <router-view />
    </div>

    <footer class="footer">
      <div class="row rowCstm">
        <div class="col-4 p-0">
          <img src="../src/assets/newlogo.png" width="160" />
        </div>
        <div class="col-8 consulting" style="text-align: right">
          <h4 style="
              font-family: 'Inter';
              font-weight: 400;
              font-size: 16px;
              padding-top: 1.2%;
            ">
            Shaza Interior Contractor & Consultant
          </h4>
        </div>
        <div class="col-4 d-flex justify-content-end p-0">
          <!--<div>
            <a href="#" target="_blank"
              ><img src="../src/assets/facebook.png" width="18" class="mr-3"
            /></a>
            <a href="#" target="_blank"
              ><img src="../src/assets/linkedin.png" width="18" class="mr-3"
            /></a>
            <a href="https://www.youtube.com/@ONEOKROCK" target="_blank"
              ><img
                src="../src/assets/youtube.png"
                width="18"
                class="mr-3 mt-1"
            /></a>
            <a href="#" target="_blank"
              ><img src="../src/assets/instagram.png" width="18" class=""
            /></a>
          </div>-->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>
