import { createWebHistory, createRouter } from "vue-router";

const routes =  [
    {
      path: "/",
      alias: "/",
      name: 'Porto',
      component: () => import("./components/MainLanding")
    },
    {
      path: "/About",
      alias: "/About",
      name: "About",
      component: () => import("./components/AboutPage")
    },
    {
      path: "/Detail",
      alias: "/Detail",
      name: "PortoDetail",
      component: () => import("./components/DetailPage"),
      props: route =>({
        id : route.query.id,
        nameProject : route.query.nameProject
      })
    },
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
  export default router;